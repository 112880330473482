<div *ngIf="status; else noStatus">
  <div class="device-status" [matTooltip]="(status.ts | date:'medium') ?? ''" [matTooltipShowDelay]="500">
    <span class="device-status-label" *ngIf="showLabel">
      {{ 'DEVICE.STATUS.LABEL' | translate }}
    </span>
    <span class="device-status-value device-status-online" *ngIf="online">
      {{ 'DEVICE.STATUS.ONLINE' | translate }}
    </span>
    <span class="device-status-value device-status-offline" *ngIf="!online">
      {{ 'DEVICE.STATUS.OFFLINE' | translate }}
    </span>
  </div>
</div>
<ng-template #noStatus>
  <div class="device-status">
    <span class="device-status-label" *ngIf="showLabel">
      {{ 'DEVICE.STATUS.LABEL' | translate }}
    </span>
    <span class="device-status-value device-status-error" *ngIf="!loading">
      {{ 'DEVICE.STATUS.NO_RESPONSE' | translate }}
    </span>
    <span class="device-status-value device-status-error" *ngIf="loading">
      {{ 'DEVICE.STATUS.LOADING' | translate }}
    </span>
  </div>
</ng-template>

import { Component, Input, OnDestroy, OnInit } from "@angular/core"
import { Subscription } from "rxjs"
import { Device } from "../../../../interfaces/device"
import { DeviceStatus } from "../../../../interfaces/device-status"
import { DeviceService } from "../../../../services/device/device.service"
import * as _moment from "moment"
import { default as _rollupMoment } from "moment"
import { DatePipe, NgIf } from "@angular/common"
import { MatTooltipModule } from "@angular/material/tooltip"
import { TranslateModule } from "@ngx-translate/core"

const moment = _rollupMoment || _moment

@Component({
    selector: "app-device-status",
    templateUrl: "./device-status.component.html",
    styleUrls: ["./device-status.component.scss"],
    standalone: true,
    imports: [NgIf, DatePipe, MatTooltipModule, TranslateModule]
})
export class DeviceStatusComponent implements OnInit, OnDestroy {

  @Input() device!: Device
  @Input() showLabel = false

  subscriptions: Subscription = new Subscription()

  online = false
  loading = true
  status!: DeviceStatus

  constructor(
    private deviceService: DeviceService
  ) { }

  ngOnInit(): void {
    if (this.device.status) {
      this.status = this.device.status
      this.online = moment().diff(moment(this.status.ts), "minutes") <= 15
      this.loading = false
    } else {
      this.retrieveDeviceStatus()
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  retrieveDeviceStatus(): void {
    this.subscriptions.add(
      this.deviceService.getDeviceStatus(this.device.id).subscribe(status => {
        this.status = status
        this.online = moment().diff(moment(this.status.ts), "minutes") <= 15
        this.loading = false
      }, error => {
        this.loading = false
      })
    )
  }
}
